import React, { useEffect } from "react";
import { route } from "preact-router";

const Redirect = ({ to }) => {
  useEffect(() => {
    route(to, true);
  });
  return null;
};

export default Redirect;
