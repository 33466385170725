/* eslint-disable no-undef */
export const trackEvent = (e, meta) => {
  if (e && window.metrical && window.metrical.trackEvent) {
    window.metrical.trackEvent(e, meta);
  }
};

export default {
  trackEvent,
};
